import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export const useLangStore = defineStore('lang', {
    state: () => {
        return {
            trans: useStorage('trans', []),
        }
    },

    actions: {},
});
