import { createRouter, createWebHistory } from '@ionic/vue-router';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MenuLayout.vue'),
    redirect: { name: 'login' },
    children: [
      {
        path: 'dashboard/:id?',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue')
      },
      {
        path: 'addresses',
        name: 'addresses',
        component: () => import('@/views/Addresses.vue')
      },
      {
        path: 'calendar',
        name: 'calendar',
        component: () => import('@/views/Calendar.vue')
      },
      {
        path: 'address/:id',
        name: 'address',
        component: () => import('@/views/Address.vue')
      },
      {
        path: 'workorder/:id',
        name: 'workorder',
        component: () => import('@/views/WorkOrder.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'incidences',
        name: 'incidences',
        component: () => import('@/views/Incidences.vue')
      },
      {
        path: 'workorders',
        name: 'workorders',
        component: () => import('@/views/WorkOrders.vue')
      },
      {
        path: 'budgets',
        name: 'budgets',
        component: () => import('@/views/Budgets.vue')
      },
      {
        path: 'budget/:id',
        name: 'budget',
        component: () => import('@/views/Budget.vue'),
        meta: { requiresAuth: true },
      },
      {
        path: 'incidence/:id',
        name: 'incidence',
        component: () => import('@/views/Incidence.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'documents',
        name: 'documents',
        component: () => import('@/views/Documents.vue')
      },
      {
        path: 'document/:id?',
        name: 'document',
        component: () => import('@/views/DocumentsDirection.vue'),
      },
      // {
      //   path: 'qr_reader',
      //   name: 'qr_reader',
      //   component: () => import('@/views/QRReader.vue'),
      // },
      {
        path: 'actives',
        name: 'actives',
        component: () => import('@/views/AssetsClients.vue'),
      },
      {
        path: 'assets/:id',
        name: 'assets',
        component: () => import('@/views/Assets.vue'),
      },
      {
        path: 'asset/:id?/:qr?',
        name: 'asset',
        component: () => import('@/views/Asset.vue'),
      },
      {
        path: 'preventive_future',
        name: 'preventive_future',
        component: () => import('@/views/PreventiveFuture.vue'),
      },
      {
        path: 'expenses',
        name: 'expenses',
        component: () => import('@/views/Expenses.vue'),
      },
      {
        path: 'expenses/:id',
        name: 'expense-item',
        component: () => import('@/views/ExpenseItem.vue'),
      },
      {
        path: 'settings',
        name: 'settings',
        component: () => import('@/views/Settings.vue'),
      },
      {
        path: 'profile-config',
        name: 'profile-config',
        component: () => import('@/views/ProfileConfig.vue'),
      },
      {
        path: 'survey/:id',
        name: 'survey',
        component: () => import('@/views/Survey.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/BlankLayout.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/Login.vue')
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/BlankLayout.vue'),
    children: [
      {
        path: 'reset',
        name: 'reset',
        component: () => import('@/views/ResetPass.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;