import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import ca from './ca.json';
import pt from './pt.json';
import de from './de.json';
import ro from './ro.json';
import it from './it.json';

export default {
  en,
  'en-US': en,
  es,
  'es-ES': es,
  fr,
  ca,
  pt,
  de,
  ro,
  it
};