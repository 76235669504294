import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/gmao.css';
import './theme/transitions.css';
import '@fullcalendar/common/main.css';

import { FontAwesomeIcon } from "@/lib/font-awesome";

/* Axios */
import axios from 'axios';

/* Moment */
import * as moment from 'moment';

/* Pinia */
import { createPinia } from 'pinia'

/* Signature */
import VueSignaturePad from 'vue-signature-pad';

/* i18n */
import { createI18n } from 'vue-i18n';

/* Capacitor Camera */
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';

/* PWA Elements */
// import { defineCustomElements } from '@ionic/pwa-elements/loader';
// defineCustomElements(window);

import messages from '@/i18n';

const i18n = createI18n({
  locale: navigator.language,
  globalInjection: true,
  messages,
})

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(VueSignaturePad)
  .use(i18n)
  .use(createPinia());

app.provide('axios', axios);


// PINIA GMAO
import { useGmaoStore } from '@/stores/gmao';
const gmao:any = useGmaoStore();

// PINIA TRADUCCIONES
import { useLangStore } from '@/stores/lang';
const lang: any = useLangStore();

axios.defaults.params = {}
axios.defaults.params['version'] = gmao.v;

// SOBREESCRIBIR LA FUNCIÓN $t DE i18n PARA INCLUIR TRADUCCIONES
const t = i18n.global.t;
i18n.global.t = (key: string) => {
  return lang?.trans?.find((trans: any) => trans?.campo == key)?.mensaje || t(key);
}

/* Onesignal */
import OneSignal from 'onesignal-cordova-plugin';
document.addEventListener("deviceready", OneSignalInit, false);

function OneSignalInit(): void {
  // Inicialización
  OneSignal.initialize("045d387e-728a-4aab-bb7c-4565ed97b041");

  // Configurar notificaciones
  const notificationPermission = async () => {
    try {
      const response = await OneSignal.Notifications.permissionNative();
      console.log("User accepted notifications:", response);
    } catch (error) {
      console.error("Error requesting permission:", error);
    }
  };

  // Manejador cuando se abre una notificación
  OneSignal.Notifications.addEventListener('click', (event) => {
    console.log('Notification clicked:', event);
  });

  // Escuchar cambios en la suscripción
  OneSignal.User.pushSubscription.addEventListener('change', (event) => {
    console.log('Push subscription changed:', event);
    app.config.globalProperties.$oneSignalState = {
      isSubscribed: event
    };
  });

  // Iniciar el proceso de permisos
  notificationPermission();
}

/* Sentry */

import * as Sentry from '@sentry/vue'; 

/// Inicializamos Sentry
Sentry.init({
  dsn: "https://1447b11ea3d99203cc5b6ad2880bfddd@sentry.tecneca.com/5",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, 
  tracePropagationTargets: ["localhost", /^https:\/\/cli\.gmaocloud\.es/],
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 1.0,
});

/* Global vars */

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$oneSignal = OneSignal;
app.config.globalProperties.$Camera = Camera;
app.config.globalProperties.$CameraResultType = CameraResultType;
app.config.globalProperties.$CameraSource = CameraSource;
app.config.globalProperties.$Sentry = Sentry;


/* Object to FormData */

app.config.globalProperties.$objectToFormData = (data: object) => {
  const formdata = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formdata.append(key, (value || ''));
  }

  return formdata;
}

/* Base64 to Blob */

app.config.globalProperties.$base64toBlob = (b64Data: string, contentType = 'image/png', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};

app.config.globalProperties.$getBase64Image = async (url: string, contentType = 'image/png') => {
  return new Promise((resolve) => {
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.src = url;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext("2d");
      if(ctx) {
        ctx.drawImage(img, 0, 0);
        const data = canvas.toDataURL(contentType);
        resolve(data);
      }
    }
  });
}

app.config.globalProperties.$getBase64Size = (base64: string, contentType = 'image/png') => {
  const stringLength = base64.length - `data:${contentType};base64,`.length;
  return 4 * Math.ceil((stringLength / 3)) * 0.5624896334383812;
}

/* Compressor JS */
import Compressor from 'compressorjs';

app.config.globalProperties.$compressImage = async (file: File | Blob, resolve: () => void, reject: () => void) => {
  new Compressor(file, {
    quality: 1,
    maxWidth: 1024,

    success: resolve,
    error: reject
  });
}

app.config.globalProperties.$getContrast = (hexColor: string) => {
  // Convierte el color hexadecimal a valores RGB
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Calcula el brillo del color de fondo
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;

  // Decide si el texto debe ser blanco o negro
  return brightness > 128 ? 'black' : 'white';
};

type Permission = {
  todos: number,
  ver: number,
  crear: number,
  editar: number,
  borrar: number,
};

app.config.globalProperties.$checkPermissions = (data: Permission, permission: string) => {
  if (data) {
    switch(permission) {
      case 'ver': return data.todos || data.ver;
      case 'crear': return data.todos || data.crear;
      case 'editar': return data.todos || data.editar;
      case 'borrar': return data.todos || data.borrar;
    }
  }

  return false;
}

app.config.globalProperties.$hasPermissions = (label: string, permission: string) => {
  const permisos = gmao?.user?.permisos;
  const permisosG = gmao?.user?.permisosG;

  if (typeof permisos?.[label]?.[permission] !== 'undefined' || typeof permisos?.[label]?.todos !== 'undefined') {
    return permisos?.[label]?.[permission] || permisos?.[label]?.todos;
  }

  return permisosG?.[label]?.[permission] || permisosG?.[label]?.todos;
}

interface GenericSistema {
  [key: string]: any; // This allows any key with any type of value.
  level?: number;
  name?: string;
  all_sistemas?: GenericSistema[];
}

/**
 * Funcion recursiva de recorrido de los sistemas de un array de sistema padre.

 * @param sistemas
 * @param level
 * @param parent
 * @param gmao
 * @returns GenericSistema[]
 */
app.config.globalProperties.$getAllSistemas = (sistemas: GenericSistema[], level: number = 0, parent: string = ''): GenericSistema[] => {
  sistemas?.forEach((ss) => {
    ss.level = level;
    ss.name = level && (!+gmao.comportamientos?.gmao_interno || +gmao.comportamientos?.ver_direccion_en_instalacion) ?
      `${parent} - ${ss['nombre']}` : (ss['id'] !== 1) ? `> ${ss['nombre']}` : ss['nombre'];
    if (ss.all_sistemas?.length) {
      sistemas = sistemas.concat(
        app.config.globalProperties.$getAllSistemas(ss.all_sistemas, level + 1, ss.name)
      );
    }
  });

  return sistemas;
}

app.config.globalProperties.$newPopup = (url: string) => {
  window.open(url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
}

app.config.globalProperties.$uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
  );
}

router.isReady().then(() => {
  // eslint-disable-next-line vue/multi-word-component-names
  app.component("fa", FontAwesomeIcon).mount('#app');
});